define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/ShopModule/models/CalendarAppointment',

  'modules/common/components/moment',
], (
  $, _, Backbone, ItemModel,
  Moment,
) => ItemModel.extend({

  calculateTotalDuration() {
    const orderItems = this.get('order.order_items') || [];
    let allMinutes = 0;
    orderItems.forEach((item) => {
      if ('extra' in item && 'duration_in_minutes' in item.extra) {
        allMinutes += parseInt(item.extra.duration_in_minutes);
      }
    });
    return {
      hour: parseInt(allMinutes / 60),
      minute: allMinutes % 60,
    };
  },

  calculateEndDate() {
    const endDate = new Moment(this.get('item.date_start'));
    const duration = this.calculateTotalDuration();
    endDate.add('hours', duration.hour).add('minutes', duration.minute);
    this.set('item.date_end', endDate.format());
  },

}));
