define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.common/components/mode',

  './ipcElectron',
  './ipcNode',
  './ipcReactNative',
  './ipcDefault',
], (
  $, _, Backbone, Mode,
  IPCElectron, IPCNode, IPCReactNative, IPCDefault,
) => {
  if (Mode.isInElectronMode()) {
    return IPCElectron;
  }
  if (Mode.isInAppMode()) {
    return IPCReactNative;
  }

  if (
    !Mode.isInElectronMode()
    && !Mode.isInAppMode()
    && !Mode.isInWebMode()
  ) {
    return IPCNode;
  }

  return IPCDefault;
});
