define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/models/datatables/BaseModel',
  'modules/common/components/locale',
], (
  $, _, Backbone, BaseModel, Locale,
) => BaseModel.extend({
  defaults: {
    name: null, // Datatable name
    limit: 10, // the current limit
    page: 0, // Developer start @ 0!
    search: '', // Any falsy or non string value will be ignored and cleaned to ""
    collectionTotal: 0, // The total amount of items in the collection
    sortName: null, // The sorting name, when empty its unsortable
    sortDir: null, // The sorting direction. only the first one in the collection is being used
    defaultFilters: [],
    loading: false, // If the loading indicator should be visible.
    settingsShown: false, // If the settings are shown

    noDataText: Locale.translate('no_data_found'), // This will be shown if there is no data in the table
    noDataLink: false, // This will be shown when you have settings allied.
    noFilteredDataText: Locale.translate('nothing_found_with_current_filters'), // This will be shown when you have settings allied.
    noFilteredDataLink: false, // This will be shown when you have settings allied.
    noSearchResultsText: Locale.translate('nothing_found_for_0'), // This will be shown when you are searching without any results
    noSearchResultsLink: false, // This will be shown when you are searching without any results
  },

  __translation() {
    Locale.translate('no_data_found');
    Locale.translate('nothing_found_with_current_settings');
    Locale.translate('nothing_found_for_0');
  },

  idAttribute: 'name',

  isValid() {
    return this.has('name');
  },

  hasNextPage() {
    return ((this.get('page') + 1) * this.get('limit')) < this.get('collectionTotal');
  },

  goNextPage() {
    if (this.hasNextPage()) {
      const page = this.get('page');
      this.set('page', page + 1);
    }
  },

  hasPreviousPage() {
    return this.get('page') >= 1;
  },

  goPreviousPage() {
    if (this.hasPreviousPage()) {
      const page = this.get('page');
      this.set('page', page - 1);
    }
  },

  showLoader(def) {
    def = def || new $.Deferred(); // Make sure there is an deferred object

    // show loader
    this.set('loading', true);

    // hide loader on resolve of reject
    def.always(() => {
      this.set('loading', false);
    });

    return def;
  },

  hideLoader() {
    this.set('loading', false);
  },

}));
