define([
  'jquery',
  'underscore',
  'backbone',
  './abstractIpc',

  'socket.io-client',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
], (
  $, _, Backbone, AbstractIPC,
  SocketIOClient, CashRegisterApi,
) => {
  const Model = AbstractIPC.extend({
    logPrefix: '[NodeIPC]',

    initialize() {
      this.socket = null;
      this.set('available', false);
    },

    isAvailable() {
      return this.get('available');
    },

    async setup() {
      const wsUrl = CashRegisterApi.getWebsocketUrl();
      const socket = SocketIOClient.io(wsUrl);

      socket.on('connect', () => {
        // Connection successful :D
        this.set('available', true);
        console.debug(`${this.logPrefix} Connection available`);
      });

      socket.on('connect_error', () => {
        this.set('available', false);
      });

      socket.on('disconnect', () => {
        console.debug(`${this.logPrefix} Connection lost`);
        this.set('available', false);
      });

      this.socket = socket;
    },

    ipcOn(event, asyncCallback) {
      if (!this.socket) {
        console.error(`${this.logPrefix} Trying to listen to socket while socket is not set up`);
        return;
      }

      this.socket.on(event, (data, callback) => {
        const errorResult = (error) => {
          callback({
            success: false,
            error: JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error))),
          });
        };

        try {
          const promise = Promise.resolve(asyncCallback(data));
          promise.then((resultData) => {
            callback({
              success: true,
              data: resultData,
            });
          }).catch(errorResult);
        } catch (error) {
          errorResult(error);
        }
      });
    },

    ipcOff(event) {
      if (!this.socket) {
        console.error(`${this.logPrefix} Trying to remove ipc listener while no socket is set up`);
        return;
      }
      this.socket.off(event);
    },

    ipcSend(event, data) {
      return new Promise((resolve, reject) => {
        if (!this.socket) {
          reject(
            new Error('No websocket initialized'),
          );
          return;
        }

        this.socket.emit(event, data, (response) => {
          if (response.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
        });
      });
    },
  });
  return new Model();
});
