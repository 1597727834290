define([
  'underscore',
  'modules/shop.cash-register-retail/models/datatables/BaseModel',
], (
  _, BaseModel,
) => BaseModel.extend({
  inAttribute: 'value',

  defaults: {
    value: null,
    label: null,
  },

  isValid() {
    return this.has('value') && this.has('label');
  },
}));
