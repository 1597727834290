define([
  'jquery',
  'underscore',
  'backbone',

  'modules/common/components/uuid',
], (
  $, _, Backbone,
  Uuid
) => {
  /**
   * TODO: [DEPRECATED] Should be removed when all React Native apps are updated to use the new IPC
   */
  const Model = Backbone.Model.extend({

    listeners: [],

    register() {
      if(!window.ReactNativeWebView) {
        console.warn('[RNWebView] ReactNativeWebViewHandler tried to register, but ReactNativeWebView was not found');
        return;
      }

      this.webviewInstance = window.ReactNativeWebView;

      /**
       * We need to add the event listener to both the window and the document because iOS and
       * Android both have a different webview implementations.
       * https://stackoverflow.com/questions/41160221/react-native-webview-postmessage-does-not-work#comment99940110_41727309
       */
      document.addEventListener('message', (message) => this.handleMessage(message));
      window.addEventListener('message', (message) => this.handleMessage(message));
    },

    handleMessage(rawMessage) {
      try {
        const message = JSON.parse(rawMessage.data);

        if(
          typeof message !== 'object' ||
          !message.id
        ) return;

        let handled = false;
        for(let i = 0; i < this.listeners.length; i++) {
          const listener = this.listeners[i];

          if(listener.message.id === message.id) {
            listener.resolve(message.data);
            clearTimeout(listener.timeoutId);
            this.listeners.splice(i, 1);

            handled = true;
            break;
          }
        }

        if(!handled) {
          console.debug(`[RNWebView] Message ${message.id} not handled`);
        }

      } catch (e) {
        console.error('[RNWebView] Could not handle message', e, rawMessage);
      }
    },

    sendMessage(type, data = {}) {
      if(!this.webviewInstance) return false;

      const message = {
        id: Uuid.genRandom(),
        type,
        data
      }

      this.webviewInstance.postMessage(JSON.stringify(message));

      return message;
    },

    sendMessageExpectResponse(type, data = {}, timeoutInSeconds = 20) {
      const message = this.sendMessage(type, data);

      if(!message) return Promise.reject();

      return this.listenForResponse(message, timeoutInSeconds)
    },

    listenForResponse(message, timeoutInSeconds = 20) {
      return new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => reject(new Error('Timeout on ReactNativeWebView message response')), timeoutInSeconds * 1000);

        const listener = {
          message,
          timeoutId,
          resolve
        }

        this.listeners.push(listener);
      })
    }

  });

  return new Model();
});