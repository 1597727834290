define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/upx/components/upx',
  'modules/shop.cash-register-retail/components/environment',

  'modules/shop.cash-register-retail/events/connection/backendConnected',
  'modules/shop.cash-register-retail/events/connection/backendDisconnected',
  'modules/shop.cash-register-retail/events/connection/internetConnected',
  'modules/shop.cash-register-retail/events/connection/internetDisconnected',

  'modules/shop.cash-register-retail/components/networkDisruptionStorage',
], (
  $, _, Backbone,
  CashRegisterApi, Upx, Environment,
  BackendConnectedEvent, BackendDisconnectedEvent, InternetConnectedEvent, InternetDisconnectedEvent,
  NetworkDisruptionStorage,
) => {
  const Model = Backbone.Model.extend({

    connectionQualityGood: 'good',
    connectionQualityMedium: 'medium',
    connectionQualityBad: 'bad',

    defaults: {
      internet: false,
      backend: false,
      connectionQuality: 'good',
    },

    register() {
      this.on('change:backend', this.storeConnectionResult, this);

      if ('connection' in navigator) {
        const self = this;
        navigator.connection.onchange = function () {
          self.checkConnection();
        };
      } else {
        console.warn('navigator.connection not found, connection changes not detected');
      }
    },

    isConnected() {
      return this.get('internet') && this.get('backend');
    },

    isBackendConnected() {
      return this.get('backend');
    },

    isInternetConnected() {
      return this.get('internet');
    },

    checkConnection() {
      const def = new $.Deferred();

      Upx.call('ManagementModule', 'getTime')
        .then(
          () => {
            this.set({
              internet: true,
              backend: true,
            });
            def.resolve(true);
            this._triggerInternetEvent();
            this._triggerBackendEvent();
          },
          () => {
            this.set({
              internet: false,
              backend: false,
            });
            def.resolve(false);
            this._triggerInternetEvent();
            this._triggerBackendEvent();
          },
        );

      return def;
    },

    async getConnectionStatistics() {
      const storage = NetworkDisruptionStorage;
      const statistics = await storage.getStatistics();
      statistics.connectionQuality = this.get('connectionQuality');
      return statistics;
    },

    async storeConnectionResult(result) {
      const storage = NetworkDisruptionStorage;
      const timestamp = new Date().getTime();

      await storage.write(this.isBackendConnected(), timestamp);
      if (!this.isBackendConnected()) {
        CashRegisterApi.log('network_disruption', 'NETWORK_CALL_TIMEOUT', timestamp)
          .fail((errorResponse) => {
            console.warnOriginal('Failed to log the "network disruption"', timestamp, errorResponse);
          });
      }
    },

    startupConnectionCheck() {
      const def = new $.Deferred();

      const self = this;
      this.checkConnection()
        .then((success) => {
          if (!success) {
            let interval;
            interval = setInterval(() => {
              self.checkConnection()
                .then((success) => {
                  if (success) {
                    clearInterval(interval);
                    location.reload();
                  }
                });
            }, 2000);
          }
        });

      return def;
    },

    _triggerInternetEvent() {
      let event;
      if (this.isInternetConnected()) {
        event = new InternetConnectedEvent();
      } else {
        event = new InternetDisconnectedEvent();
      }
      event.trigger();
    },

    _triggerBackendEvent() {
      let event;
      if (this.isBackendConnected()) {
        event = new BackendConnectedEvent();
      } else {
        event = new BackendDisconnectedEvent();
      }
      event.trigger();
    },

  });

  return new Model();
});
