define([
  'jquery',
  'underscore',
  'backbone',
], (
  $, _, Backbone,
) => {
  const Model = Backbone.Model.extend({

    MODE_CUSTOMER_SEARCH: 'customer_search',
    MODE_PRODUCT_SEARCH: 'product_search',
    MODE_PRODUCT_QUANTITY: 'product_quantity',
    MODE_PRODUCT_DISCOUNT: 'product_discount',
    MODE_PRODUCT_PPU: 'product_ppu',
    MODE_PRODUCT_PRICE: 'product_price',
    MODE_PRODUCT_DESCRIPTION_LENGTH: 'product_description_length',
    MODE_PRODUCT_DESCRIPTION_PRICE: 'product_description_price',
    MODE_PRODUCT_ORDER_DESCRIPTION: 'product_order_description',
    MODE_PRODUCT_SERIALS: 'product_serials',
    MODE_ALL_DISCOUNT: 'all_discount',
    MODE_PAYMENT_VALUE: 'payment_value',
    MODE_SCAN_POPUP: 'scan_popup',
    MODE_INPUT_POPUP: 'input_popup',
    MODE_POPUP: 'popup',
    MODE_SEARCH_RECEIPT: 'scan_receipt',
    MODE_RETURN_QUANTITY: 'return_quantity',
    MODE_GIFTCARD_CODE: 'giftcard_code',
    MODE_GIFTCARD_BALANCE: 'giftcard_balance',
    MODE_MEMBERCARD_CODE: 'membercard_code',
    MODE_MEMBERCARD_BALANCE: 'membercard_balance',
    MODE_CASHDRAWER: 'cashdrawer',
    MODE_CASH: 'cash',
    MODE_INTEGER: 'integer',
    MODE_STRING: 'string',
    MODE_SERIAL_SEARCH: 'serial_search',
    MODE_TEXT: 'text',
    MODE_ORDER_SEARCH: 'order_search',
    MODE_EVENT_TIME_MODE: 'event-time-mode',
    MODE_PRODUCT_CATALOG_SEARCH: 'product-catalog-search',
    MODE_QUANTITY_POPUP: 'quantity-popup',

    defaults: {
      viewId: null,
      mode: null,
      highlightedKeys: [],
    },

    initialize() {
      this.startListening();
    },

    addKey(key) {
      const keys = this.get('highlightedKeys');
      if (!keys.indexOf(key)) {
        this.unset('highlightedKeys', { silent: true });
        keys.push(key);
        this.set('highlightedKeys', keys);
      }
    },

    removeKey(key) {
      let keys = this.get('highlightedKeys');
      keys = _.without(keys, key);
      this.set('highlightedKeys', keys);
    },

    resetKeys() {
      this.unset('highlightedKeys', { silent: true });
      this.set('highlightedKeys', []);
    },

    setKey(key) {
      this.unset('highlightedKeys', { silent: true });
      this.set('highlightedKeys', [key]);
    },

    isKeySet(key) {
      const keys = this.get('highlightedKeys');
      return keys.indexOf(key);
    },

    startListening() {
      const self = this;
      document.addEventListener('keydown', (event) => {
        self.handleKeyboardEvent(event);
      });
    },

    stopListening() {
      const self = this;
      document.removeEventListener('keydown', (event) => {
        self.handleKeyboardEvent(event);
      });
    },

    /**
         * @param event KeyboardEvent
         */
    handleKeyboardEvent(event) {
      const keyPressed = event.key;
      const codePressed = event.code;

      // Check if there was a keypress
      if (!keyPressed || !codePressed) {
        return; // ignore keypress
      }

      if (keyPressed.length === 1 || keyPressed === '00') { // 00 is from onscreen keypad
        this.trigger('key:pressed', keyPressed);
      }
      // Special key pressed
      else {
        switch (codePressed) {
          case 'Enter':
          case 'NumpadEnter':
            this.trigger('key:confirm');
            break;
          case 'Escape':
            this.trigger('key:cancel');
            break;
          case 'Backspace':
            this.trigger('key:backspace');
            break;
          case 'Tab':
            event.shiftKey ? this.trigger('key:tab_shift', event) : this.trigger('key:tab', event);
            break;
        }
      }
    },

    resetMode() {
      this.setNeutralMode();
    },

    hasMode() {
      return this.has('mode');
    },

    hasView() {
      return this.has('viewId');
    },

    isMode(wantedMode) {
      return this.get('mode') === wantedMode;
    },

    isView(viewId) {
      return this.get('viewId') === viewId;
    },

    isViewAndMode(viewId, wantedMode) {
      return this.isMode(wantedMode) && this.isView(viewId);
    },

    isNeutralMode() {
      return !this.hasMode() || !this.hasView();
    },

    setViewWithMode(viewId, mode) {
      this.set({
        viewId,
        mode,
      });
    },

    setNeutralMode() {
      this.setViewWithMode(null, null);
    },

    customerSearchMode(viewId) {
      this.setViewWithMode(viewId, this.MODE_CUSTOMER_SEARCH);
    },

    productSearchMode(viewId) {
      this.setViewWithMode(viewId, this.MODE_PRODUCT_SEARCH);
    },

    stringMode(viewId) {
      this.setViewWithMode(viewId, this.MODE_STRING);
    },

    isModeWithConfirmation() {
      switch (this.get('mode')) {
        case this.MODE_PAYMENT_VALUE:
        case this.MODE_PRODUCT_QUANTITY:
        case this.MODE_PRODUCT_DISCOUNT:
        case this.MODE_PRODUCT_PPU:
        case this.MODE_PRODUCT_PRICE:
        case this.MODE_CASH:
        case this.MODE_ALL_DISCOUNT:
        case this.MODE_PRODUCT_DESCRIPTION_PRICE:
        case this.MODE_PRODUCT_DESCRIPTION_LENGTH:
          return true;
      }
      return false;
    },

    allPaymentMode(viewId) {
      this.setViewWithMode(viewId, this.MODE_PAYMENT_VALUE);
    },

    scanPopupMode(viewId) {
      this.setViewWithMode(viewId, this.MODE_SCAN_POPUP);
    },

    popupMode(viewId) {
      this.setViewWithMode(viewId, this.MODE_POPUP);
    },

    giftcardCodeMode(viewId) {
      this.setViewWithMode(viewId, this.MODE_GIFTCARD_CODE);
    },

    giftcardBalanceMode(viewId) {
      this.setViewWithMode(viewId, this.MODE_GIFTCARD_BALANCE);
    },

    membercardCodeMode(viewId) {
      this.setViewWithMode(viewId, this.MODE_MEMBERCARD_CODE);
    },

    membercardBalanceMode(viewId) {
      this.setViewWithMode(viewId, this.MODE_MEMBERCARD_BALANCE);
    },

    scanReceiptMode(viewId) {
      this.setViewWithMode(viewId, this.MODE_SEARCH_RECEIPT);
    },

    returnQuantityMode(viewId) {
      this.setViewWithMode(viewId, this.MODE_RETURN_QUANTITY);
    },

    cashDrawerMode(viewId) {
      this.setViewWithMode(viewId, this.MODE_CASHDRAWER);
    },

    orderNumberMode(viewId) {
      this.setViewWithMode(viewId, this.MODE_ORDER_SEARCH);
    },
    isNumberKey(key) {
      const int = parseInt(key);
      return !_.isNaN(int) && _.isNumber(int);
    },
    isLetter(key) {
      return /^[a-zA-Z]+$/.test(key);
    },
    isAlphaNumeric(key) {
      return this.isNumberKey(key) || this.isLetter(key);
    },
    isNumberComaKey(key) {
      return key === ',' || key === '.' || this.isNumberKey(key);
    },

    isNumberComaPercentKey(key) {
      return this.isNumberComaKey(key) || key === '%';
    },

  });

  return new Model();
});
