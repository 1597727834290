define([
  './paymentMethodItem',
], (
  PaymentMethodItemModel,
) => PaymentMethodItemModel.extend({

  modelEvents: {
    change: 'save',
    ...PaymentMethodItemModel.prototype.modelEvents,
  },

  setPpuWt(ppuWt) {
    PaymentMethodItemModel.prototype.setPpuWt.call(this, ppuWt);
    this.save();
  },
}));
