define([
  'jquery',
  'underscore',
  'backbone',
], (
  $, _, Backbone,
) => Backbone.Model.extend({
  defaults: {
    available: false,
  },
  isAvailable() {
    console.warn('[IPC] No IPC implementation available.');
    return this.get('available');
  },
  setup() {
    throw new Error('[IPC] Method "setup" is not implemented');
  },
  ipcOn() {
    throw new Error('[IPC] Method "ipcOn" is not implemented');
  },
  ipcOff() {
    throw new Error('[IPC] Method "ipcOff" is not implemented');
  },
  ipcSend() {
    throw new Error('[IPC] Method "ipcSend" is not implemented');
  },
}));
