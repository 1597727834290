define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.common/components/deviceConfig',
  'modules/shop.cash-register-retail/models/settings/shopPos',
], (
  $, _, Backbone, DeviceConfig, ShopPosSetting,
) => {
  const Model = Backbone.Model.extend({

    initialize() {
      ShopPosSetting.on('change', () => this.trigger('layout:changed'));
    },

    showCalendarInMenu() {
      return DeviceConfig.getDeviceMode() === DeviceConfig.MODE_Salon;
    },

    showStickerPrintInMenu() {
      return DeviceConfig.getDeviceMode() === DeviceConfig.MODE_Retail;
    },

  });

  return new Model();
});
