define([
  'jquery',
  'upx.modules/PaymentModule/models/GiftCard',
  'upx.modules/PaymentModule/collections/Provider',
], ($, ShopModule, ProviderCollection) => {
  const model = ShopModule.extend({

    offline: true,

    loaded: false,

    load(force) {
      const def = $.Deferred();
      if (!this.loaded || force) {
        const self = this;
        const params = {
          params: {
            start: 0,
            limit: 1,
            filters: [{
              name: 'provider_type/alias__=',
              val: 'GiftCard',
            }],
          },
        };
        const providerCollection = new ProviderCollection();

        $.when(providerCollection.fetch(params)).then((response) => {
          self.set(response.data[0]);
          self.loaded = true;
          def.resolve();
        }, () => {
          def.reject('Failed to load model!');
        });
      } else {
        def.resolve();
      }
      return def.promise();
    },

    unload() {
      this.clear();
      this.getCacheStorage().cleanup();
    },
  });

  return new model();
});
