define([
  'jquery',
  'underscore',
  'backbone',
  './abstractIpc',
  'modules/common/components/uuid',
], (
  $, _, Backbone, AbstractIPC, Uuid,
) => {
  const Model = AbstractIPC.extend({
    logPrefix: '[ReactNativeIPC]',
    eventListeners: [],

    isAvailable() {
      return this.get('available');
    },
    async setup() {
      if (!window.ReactNativeWebView) {
        console.warn(`${this.logPrefix} window.ReactNativeWebView is not defined. Is this a React Native app?`);
        return;
      }
      this.webviewInstance = window.ReactNativeWebView;

      /**
       * We need to add the event listener to both the window and the document because iOS and
       * Android both have a different webview implementations.
       * https://stackoverflow.com/questions/41160221/react-native-webview-postmessage-does-not-work#comment99940110_41727309
       */
      const messageListener = (message) => this.handleMessage(message);
      messageListener.bind(this);

      document.addEventListener('message', messageListener);
      window.addEventListener('message', messageListener);

      const pingResult = await this.ipcSend('ping');
      if (pingResult === 'pong') {
        this.set('available', true);
      } else {
        console.warn(`${this.logPrefix} ping failed, the react native app may be outdated.`);
        document.removeEventListener('message', messageListener);
        window.removeEventListener('message', messageListener);
      }
    },
    ipcOn(event, callback) {
      this.addEventListener(event, callback);
    },
    ipcOff(event) {
      this.eventListeners = this.eventListeners.filter((listener) => listener.event !== event);
    },
    ipcSend(event, data) {
      return new Promise((resolve, reject) => {
        const id = Uuid.genRandom();
        const message = {
          id,
          event,
          data,
        };

        const responseEvent = `${id}-response`;
        this.addEventListener(responseEvent, (response) => {
          if (response.success) {
            resolve(response.data);
          } else {
            reject(response.error);
          }
          this.ipcOff(responseEvent);
        }, false);
        this.sendMessage(message);
      });
    },
    addEventListener(event, callback, sendResponse = true) {
      const eventCallback = (data, returnCallback) => {
        const errorResult = (error) => {
          if (sendResponse) {
            returnCallback({
              success: false,
              error: JSON.parse(JSON.stringify(error, Object.getOwnPropertyNames(error))),
            });
          } else {
            console.error(`${this.logPrefix} Received error from event callback, but specified not to send response back:`, error);
          }
        };

        try {
          const promise = Promise.resolve(callback(data));
          promise.then((resultData) => {
            if (sendResponse) {
              returnCallback({
                success: true,
                data: resultData,
              });
            }
          }).catch(errorResult);
        } catch (error) {
          errorResult(error);
        }
      };
      this.eventListeners.push({ event, callback: eventCallback });
    },
    async handleMessage(rawMessage) {
      try {
        if (typeof rawMessage.data !== 'string') {
          // Message is not in the correct format
          return;
        }
        if (rawMessage.data.trim().length === 0) {
          // Message is empty
          return;
        }
        const message = JSON.parse(rawMessage.data);

        if (
          typeof message !== 'object'
        ) {
          console.warn(`${this.logPrefix} Received message is not an object`, message, rawMessage.data);
          return;
        }

        let hasListener = false;
        for (let i = 0; i < this.eventListeners.length; i++) {
          const listener = this.eventListeners[i];

          if (listener.event === message.event) {
            hasListener = true;

            const returnCallback = (result) => {
              this.sendMessage({
                id: Uuid.genRandom(),
                event: `${message.id}-response`,
                data: result,
              });
            };
            listener.callback(message.data, returnCallback);
          }
        }

        if (!hasListener) {
          console.warn(`${this.logPrefix} Message ${message.id} event: ${message.event} not handled because no it has no listener`);
        }
      } catch (e) {
        console.error(`${this.logPrefix} Could not handle message`, e, rawMessage);
      }
    },
    sendMessage(message) {
      this.webviewInstance.postMessage(JSON.stringify(message));
    },
  });
  return new Model();
});
