define([
  'backbone',
  'modules/common/components/appLocalStorage',
  'modules/upx/components/upx',
], (
  Backbone, AppLocalStorage, Upx,
) => {
  const Model = Backbone.Model.extend({

    defaults: {
      previousOrderId: null,
      companyLogo: null,
      reloadCustomerScreen: false,
      onLoginScreen: false,
      displayCustomerInformation: false,
      qrPaymentInformation: false,
    },

    localStorage: AppLocalStorage.get('modules/shop.cash-register-retail/models/customerScreenData'),

    initialize() {
      this.fetch();
    },

    fetch() {
      const self = this;
      Backbone.DeepModel.prototype.fetch.call(this)
        .then(() => {
          self.set(self.get('0'));
          self.unset('0');
        });
    },

    unload() {
      this.clear();
      this.getCacheStorage().cleanup();
    },

    updatePreviousOrderId(orderId) {
      this.set('previousOrderId', orderId);
      this.save();
    },

    getPreviousOrderId() {
      return this.get('previousOrderId');
    },

    getCompanyLogo() {
      if (this.has('companyLogo')) {
        return Upx.getResourceUrl(this.get('companyLogo'));
      }
      return null;
    },

    setCompanyLogo(logoUrl) {
      this.set('companyLogo', logoUrl);
      this.save();
    },

    reloadCustomerScreen() {
      this.set('reloadCustomerScreen', true);
      this.save();
    },

    needsToReloadCustomerScreen() {
      return this.get('reloadCustomerScreen');
    },

    customerScreenReloaded() {
      this.set('reloadCustomerScreen', false);
      this.save();
    },

    displayCustomerInformation(customer_model) {
      this.unset('displayCustomerInformation');
      this.set('displayCustomerInformation', customer_model.toJSON());
      this.save();
    },

    removeCustomerInformation() {
      this.set('displayCustomerInformation', false);
      this.save();
    },

    displayQrInformation(
      qrImageUrl,
      qrOverlayImageUrl = null,
      statusMessage = null,
      supportedAppImages,
    ) {
      this.unset('qrInformation');
      this.set('qrInformation', {
        qrImageUrl, statusMessage, qrOverlayImageUrl, supportedAppImages,
      });
      this.save();
    },

    displayQrErrorInformation(errorMessage) {
      this.unset('qrInformation');
      this.set('qrInformation', { errorMessage });
      this.save();
    },
    displayQrSuccessInformation(successMessage) {
      this.unset('qrInformation');
      this.set('qrInformation', { successMessage });
      this.save();
    },

    removeQrInformation() {
      this.set('qrInformation', false);
      this.save();
    },

    resetData() {
      this.removeQrInformation();
      this.removeCustomerInformation();
    },

  });

  return new Model();
});
