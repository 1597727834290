define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.common/components/mode',
  'modules/common/components/locale',
], (
  $, _, Backbone,
  Mode, Locale,
) => ({
  async getUSBPrinters() {
    if (!Mode.isInElectronMode()) {
      throw new Error('Not in electron mode');
    }

    const { success, data, error } = await window.electron.getUSBPrinters();

    if (!success && !data) {
      if (error) {
        throw new Error(error.message);
      } else {
        throw new Error();
      }
    }

    return data;
  },

  async printOnReceiptPrinter(id, type, jsonData) {
    if (!Mode.isInElectronMode()) {
      throw new Error('Not in electron mode');
    }

    const {
      success, data, error,
    } = await window.electron.printOnReceiptPrinter(id, type, jsonData);
    if (!success && !data) {
      if (!error) {
        throw new Error(Locale.translate('failed_to_print_on_printer'));
      }
      if (!error.code || error.code === 'ERROR_PRINTER') {
        if (error.message) {
          throw new Error(Locale.translate('failed_to_print_with_message_{0}', [error.message]));
        } else {
          throw new Error(Locale.translate('failed_to_print_on_printer'));
        }
      }

      if (
        error.code === 'ERROR_PRINTER_CANNOT_REACH'
          || error.code === 'ERROR_PRINTER_TIMEOUT'
          || error.code === 'ERROR_PRINTER_CONNECTION_REFUSED') {
        throw new Error(Locale.translate('please_check_if_the_printer_is_turned_on_and_connected'));
      } else if (error.code === 'ERROR_PRINTER_INCOMPATIBLE_DEVICE') {
        throw new Error(Locale.translate('printer_is_not_compatible'));
      }
    }

    return data;
  },
}));
