define([
  'backbone',
  'backbone.deepmodel',
], (
  Backbone,
) => Backbone.DeepModel.extend({
  defaults: {},

  isValid() {
    throw new Error('"isValid function not set.');
  },
}));
