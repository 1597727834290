define([
  'jquery',
  'underscore',
  'backbone',
  'upx.modules/CommerceModule/models/AdminProfile',
], (
  $, _, Backbone, AdminProfileModel,
) => AdminProfileModel.extend({

}));
